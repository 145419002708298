// import Navbar from "./Components/Navbar";
import Home from "./Pages/Home";
import './App.css';

function App() {
  return (
    <Home/>
  );
}

export default App;
